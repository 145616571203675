.generic-popup__background {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.generic-popup__background.opacity {
    background-color: rgba(0,0,0,0.6);
}

.generic-popup__background.darker-blur {
    background-color: rgba(0,0,0,0.6);
    backdrop-filter: blur(10px);
}

.generic-popup__background.blur {
    backdrop-filter: blur(11.5px);
}

.generic-popup__background.enter-animation {
    animation: enter-animation 0.4s ease;
}

.generic-popup__background.enter-animation .generic-popup__card {
    animation: popup-card-enter 0.4s ease;
}

@keyframes enter-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes popup-card-enter {
    from {
        transform: translateY(-50px);
    }

    to {
        transform: translateY(0px);
    }
}