*{
  font-family: 'Montserrat', sans-serif !important; 
}

.center_hero_logo {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Desktop_logo {
  width: 30%;
  height: 100%;
}

.Mobile_logo {
  width: 90%;
  height: 100%;
}

.hero_logo_under_text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.Desktop_logo_button {
  width: 18%;
  height: 100%;
}

.Mobile_logo_button {
  width: 50%;
  height: 100%;
}

.hero_logo_under_text_mobile {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.Mobile_amr {
  width: 100%;
  height: 100%;
}

.Desktop_amr_back {
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/df7a2101-acf4-4ca4-46cb-0bdd76e43800/w=3000");
  height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;

  backface-visibility: visible;

  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-position: center center;
}

.backgroundCard {
  background-color: transparent !important;
}


.card_under_text {
  color: #ffffff;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .card_under_text {
    color: #ffffff;
    font-weight: 300;
  }
}

.desktop_footer_bg {
  background: url("./Component/Footer/image/footerbg.png");
  height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;

  backface-visibility: visible;

  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-position: center center;
}

.head_footer {
  color: #ffffff;
  font-weight: 600;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .head_footer {
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }
}

.under_footer {
  color: #ffffff;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .under_footer {
    color: #ffffff;
    font-weight: 300;
    text-align: center;
  }
}

.css-input {
  border-color: #cccccc;
  border-style: solid;
  width: 50%;
  padding: 11px;
  color: #9b9daf;
  font-size: 19px;
  border-width: 0px;
  border-radius: 9px;
  box-shadow: 0px 0px 0px 0px rgba(42, 42, 42, 0.55);
  text-shadow: 0px 0px 0px rgba(42, 42, 42, 0.62);
  background-color: #7c017091;
}
.css-input:focus {
  outline: none;
}

.mobile_footer_bg {
  background: url("./Component/Footer/image/mobfoot.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: visible;
  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-position: center center;
}



.Mobile_logo_button {
  width: 38%;
  height: 100%;
}

.head_learn{
  color: #D000C5;
  text-align: center;
  align-items: center;
font-size: 30px;
font-weight: 900;
line-height: 52px;
letter-spacing: 0.045em;


}

@media screen and (min-width: 1000px) {
  .head_learn{
    color: #D000C5;
    text-align: center;
    align-items: center;
  font-size: 67px;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: 0.045em;
  
  
  }
}

.less_more_button{

  color: #D000C5 !important;
}



.learn_under{
  color: #ffffff;
  text-align: center;
  align-items: center;
font-size: 16px;
font-weight: 300;
line-height: 32px;
letter-spacing: 0.045em;


}

.Learn_Mobile_button {
  width: 49%;
  height: 100%;
}



.head_about{
  color: #FA0194;
  text-align: center;
  align-items: center;
font-size: 30px;
font-weight: 900;
line-height: 52px;
letter-spacing: 0.045em;


}

@media screen and (min-width: 1000px) {
  .head_about{
    color: #FA0194;
    text-align: center;
    align-items: center;
  font-size: 67px;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: 0.045em;
  
  
  }
}



.padding_about{
  position: relative;
left: 10px;
}

@media screen and (min-width: 1000px) {
  .padding_about{
    position: relative;
   left: 40px;
}
}

.heading_about_top{
  color: #FA0194;
  font-weight: 800;
  font-size: 14px;
}

@media screen and (min-width: 1000px) {
  .heading_about_top{
   color: #FA0194;
   font-weight: 800;
   font-size: 18px;
}
}

.under_about_top{
  color: #ffffff;
  font-weight: 300;
  font-size: 12px;
}

@media screen and (min-width: 1000px) {
  .under_about_top{
   color: #ffffff;
   font-weight: 300;
   font-size: 16px;
}
}

.card_bg_team{
  background-color: #0daca400 !important;

}

.head_team_card{
  color: #FA0194;
  font-weight: 700;
  font-size: 12px;
}

@media screen and (min-width: 1000px) {
  .head_team_card{
    color: #FA0194;
    font-size: 16px;
    font-weight: 900;
  }
  
}

.under_team_card{
  color: #ffffff;
  font-size: 8px;
  font-weight: 400;
}
@media screen and (min-width: 1000px) {
  .under_team_card{
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
  }
  
}

.last_card_about_bg{
  background-color: #1A1A1D !important;
  padding: 1px;
}


@media screen and (min-width: 1000px) {
  .last_card_about_bg{
    background-color: #1A1A1D !important;
    padding: 10px;
  }
  
}

.last_card_para{
  font-size: 11px;
  font-weight: 300;
  color: #ffffff;
}

@media screen and (min-width: 1000px) {
  .last_card_para{
    font-size: 13px;
    font-weight: 300;
    color: #ffffff;
  }
}

.last_card_head{
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 4px;
  color: #FA0194;
}

@media screen and (min-width: 1000px) {
  .last_card_head{
    font-size: 20px;
    font-weight: 700;
    color: #FA0194;
  }  
}



.head_view{
  color: #FF3B58;
  text-align: center;
  align-items: center;
font-size: 30px;
font-weight: 900;
line-height: 52px;
letter-spacing: 0.045em;


}

@media screen and (min-width: 1000px) {
  .head_view{
    color: #FF3B58;
    text-align: center;
    align-items: center;
  font-size: 67px;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: 0.045em;
  
  
  }
}




.para_view{
  color: #ffffff;
  text-align: center;
  align-items: center;
font-size: 11px;
font-weight: 300;
line-height: 20px;
letter-spacing: 0.045em;


}

@media screen and (min-width: 1000px) {
  .para_view{
    color: #ffffff;
    text-align: center;
    align-items: center;
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.045em;
  
  
  }
}

.Desktop_logo_button_view {
  width: 13%;
  height: 100%;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;

  text-transform: none!important;
  letter-spacing: 0;
 
  font-variant: initial;
  color:  #FF3B58 !important;
  line-height: 1;
  font-size: 24px !important;
  font-weight: 800 !important;
}

.positiontop_view{
  position: relative;
  margin-top: 70px;
}




@media screen and (min-width: 1300px) {
  .positiontop_view{
    position: relative;
    top: 120px;
  }
}



.positiontop_view_image{
  position: relative;
 top: 10px;
 z-index: -10;
}




@media screen and (min-width: 1300px) {
  .positiontop_view_image{
    position: relative;
    bottom: 120px;
    z-index: -10;
  }
}

.card_head_text {
  text-align: center;
  color: #ffb500;
  font-size: 32px;
  font-weight: 700;
}

@media screen and (min-width: 1300px) {
  .card_head_text {
    text-align: center;
    color: #ffb500;
    font-size: 42px;
    font-weight: 700;
  }
}





.card_head_text_target {
  text-align: center;
  color:#FF7900;
  font-size: 32px;
  font-weight: 700;
}

@media screen and (min-width: 1300px) {
  .card_head_text_target {
    text-align: center;
    color: #FF7900;
    font-size: 42px;
    font-weight: 700;
  }
}


.undersuarta_positon{
  position: relative;
  top: 100px;
}



@media screen and (min-width: 1300px) {
  .undersuarta_positon{
  position: relative;
  top: 100px;
  }
}

.back_card{
  background-color: transparent !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent !important;
  background-clip: border-box;
  background-color: transparent !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  border: none !important;
}

.carousel-control-prev, .carousel-control-next {
  height: 50px;
}

@media (max-width: 900px) {
  .carousel-control-prev, .carousel-control-next {
    height: 40px;
  }
}

.carousel-control-prev-icon {
  background-image: url('../src//Component/SuarteWorld/image/prev.png') !important;
  width: 20px !important;
  height:  30px !important;
  opacity: 1 !important;
}

.carousel-control-prev-icon:hover{
  pointer-events: none !important;
  opacity: inherit !important;
  pointer-events: none !important;
  background-color: unset !important;
  color: unset !important;
}

.carousel-control-next-icon {
  background-image: url('../src//Component/SuarteWorld/image/next.png') !important;
  width: 20px !important;
  height:  30px !important;
  opacity: 1 !important;
 

}

.carousel-control-prev{
  opacity: 1 !important;
}
.carousel-control-next{
  opacity: 1 !important;
}

.carousel-control-prev-icon::after {
  content: none !important;
  font-weight: 700;
  font-family: "Font Awesome 5 Pro","Font Awesome 5 Free";
  font-size: 1.7rem;
  
}

.carousel-control-next-icon::after {
  content: none !important;
  font-weight: 700;
  font-family: "Font Awesome 5 Pro","Font Awesome 5 Free";
  font-size: 1.7rem;
}


.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
   filter: none !important;
}


.head_slider_suarta{
  color: #FF7900;
  text-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
}
@media screen and (min-width: 1300px) {
  .head_slider_suarta{
    color: #FF7900;
    text-align: center;
    align-items: center;
    font-size: 45px;
    font-weight: 800;
  }
}
.para_slider_suarta{
  color: #ffffff;
  text-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
}

@media screen and (min-width: 1300px) {
  .para_slider_suarta{
    color: #ffffff;
    text-align: center;
    align-items: center;
    font-size: 15px;
    font-weight: 300;
  }
}

.hole_width{
  width: 130%;
}



.hero_time_line{
  background-color: #101013;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 100;
}

.hero_time_lines{
 

  background-color: #101013;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  padding-top: 14px;
 
}

.timer{
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 47px;
  font-weight: 700;
  color: #FFB500;
}
.timers{
  text-align: end;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  color: #FFB500;
}
.Desktop_logo_button_join {

  height: 70%;
}

.Desktop_logo_button_joint {
 width: 130%;
}

.carousel-control-next, .carousel-control-prev {
  transition: none;
  bottom: 433px !important;
}

@media screen and (min-width: 1000px) {	
  .carousel-control-next, .carousel-control-prev {	
    transition: none;	
    bottom: 503px !important;	
  }	
}



.Desktop_logo_button_view_mob {
  width: 23%;
  height: 100%;
}



.mainContent {
  background-attachment: fixed;
  background-image: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/acdd2341-9912-485e-b4d5-6656b35a9e00/w=2000")!important;
  background-position: top!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  z-index: 1111;
}

.pageCon {
 
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;

}





.mainContentmob {
  background-attachment: fixed;
  background-image: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/acdd2341-9912-485e-b4d5-6656b35a9e00/w=2000") !important;
  background-position: top!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  z-index: 1111;
}

.pageConmob {
 
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;

}


.head_logo{
width: 90px;
}

@media screen and (min-width: 1000px) {
  .head_logo{

  }
}



.icons_need_small{
  width: 15%;
}

@media screen and (min-width: 1000px) {
  .icons_need_small{
             width: 25%;
  }
}





















.myButton_head_Desktop {
	background-color:#FFB5003B;
	border-radius:9px;
	border:2px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
	font-family:Verdana;
	font-size:20px;
  font-weight: 600;
	padding:12px 33px;
	text-decoration:none;
}
.myButton_head_Desktop:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Desktop:active {
	position:relative;
	top:1px;
}

.myButton_head_Mobile{
	background-color:#FFB5003B;
	border-radius:9px;
	border:1px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
	font-family:Verdana;
	font-size:16px;
  font-weight: 600;
	padding:9px 25px;
	text-decoration:none;
}
.myButton_head_Mobile:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Mobile:active {
	position:relative;
	top:1px;
}





















.myButton_head_Desktop_Join {
	background-color:#FFB5003B;
	border-radius:9px;
	border:2px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
	font-family:Verdana;
	font-size:18px;
  font-weight: 600;
	padding:10px 30px;
	text-decoration:none;
}
.myButton_head_Desktop_Join:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Desktop_Join:active {
	position:relative;
	top:1px;
}

.myButton_head_Desktop_Join_Mobile{
	background-color:#FFB5003B;
	border-radius:9px;
	border:1px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
	font-family:Verdana;
	font-size:12px;
  font-weight: 600;
	padding:13px 45px;
	text-decoration:none;
}
.myButton_head_Desktop_Join_Mobile:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Desktop_Join_Mobile:active {
	position:relative;
	top:1px;
}









































.myButton_head_Desktop_Try {
	background-color:#fa3a5757
;
	border-radius:9px;
	border:2px solid #fa3a56;
	display:inline-block;
	cursor:pointer;
	color:#fa3a56;
	font-family:Verdana;
	font-size:18px;
  font-weight: 600;
	padding:10px 30px;
	text-decoration:none;
}
.myButton_head_Desktop_Try:hover {
	background-color:#fa3a56;;
  color: #000000;
}
.myButton_head_Desktop_Try:active {
	position:relative;
	top:1px;
}

.myButton_head_Desktop_Try_Mobile{
	background-color:#fa3a5757;
	border-radius:9px;
	border:2px solid #fa3a56;
	display:inline-block;
	cursor:pointer;
	color:#fa3a56;
	font-family:Verdana;
	font-size:12px;
  font-weight: 600;
	padding:10px 30px;
	text-decoration:none;
  z-index: 999999;
}

.myButton_head_Desktop_Try_Mobile:hover {
	background-color: #fa3a56;
  color: #000000;
}
.myButton_head_Desktop_Try_Mobile:active {
	position:relative;
	top:1px;
}




























































.myButton_head_Desktop_Learn{
	background-color:#9800ac83;
	border-radius:9px;
	border:1px solid #d000c5;
	display:inline-block;
	cursor:pointer;
	color:#d000c5;
	font-family:Verdana;
	font-size:20px;
  font-weight: 700;
	padding:12px 40px;
	text-decoration:none;
}
.myButton_head_Desktop_Learn:hover {
	background-color:#d000c5;
  color: #000000;
}
.myButton_head_Desktop_Learn:active {
	position:relative;
	top:1px;
}






















.myButton_head_Mobile_Learn{
	background-color:#d000c646;
	border-radius:9px;
	border:1px solid #d000c5;
	display:inline-block;
	cursor:pointer;
	color:#d000c5;
	font-family:Verdana;
	font-size:16px;
  font-weight: 600;
	padding:9px 25px;
	text-decoration:none;
}
.myButton_head_Mobile_Learn:hover {
	background-color:#d000c5;
  color: #000000;
}
.myButton_head_Mobile_Learn:active {
	position:relative;
	top:1px;
}


.shoe_hover_desk{
  text-align: center;
  position: relative;
  left: 250px;
  top: 60px;
}

.moon_hover_desk{
  text-align: center;
  position: relative;
  left: 550px;
  top: -60px;
}






















img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}





.shoe_hover_mobile{
  text-align: center;
  position: relative;
  left:70px;
  top: -10px;
}




.css-input-mob {
  border-color: #cccccc;
  border-style: solid;
  width: 80%;
  padding: 11px;
  color: #9b9daf;
  font-size: 19px;
  border-width: 0px;
  border-radius: 9px;
  box-shadow: 0px 0px 0px 0px rgba(42, 42, 42, 0.55);
  text-shadow: 0px 0px 0px rgba(42, 42, 42, 0.62);
  background-color: #7c017091;
}
.css-input-mob:focus {
  outline: none;
}




.mobile_hero_text{
  color: #fff;
  font-size: 60px;
}

.mobile_hero_text_art{
  color: #ffab23;
  font-weight: 900;

}

.desktop_hero_text{
  color: #fff;
  font-size: 70px;
}















.mainContentfof {
  background-attachment: fixed;
  background-image: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/287f3610-3513-446d-5779-91763bdbe000/w=2000")!important;
  background-position: top!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  z-index: 1111;
}

.pageConfof {
 
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;

}





.mainContentmobfof {
  background-attachment: fixed;
  background-image: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/287f3610-3513-446d-5779-91763bdbe000/w=2000")!important;
  background-position: top!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  z-index: 1111;
}

.pageConmobfof {
 
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;

}



.card_404{
  
  background-image: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/1ffb9895-a6b3-40c5-6903-a679a5da8400/w=600")!important;
  height: 20px !important;
  width:100%  !important;
  background-repeat: no-repeat;
  background-size: cover;
background-color: transparent !important;
  backface-visibility: visible;

  background-blend-mode: multiply; /* blend mode to multiply the image and color */
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-position: center center;

}


@media screen and (min-width: 1000px) {
  .card_404{
  
    background-image: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/1ffb9895-a6b3-40c5-6903-a679a5da8400/w=600")!important;
    height: 100vh !important;
    width:50%  !important;
    background-repeat: no-repeat;
    background-size: cover;
  
    backface-visibility: visible;
  
    background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
  
  }
}








.foftext{
  color: #FFB500;
  font-size: 25px;
  
}


@media screen and (min-width: 1000px) {
  .foftext{
    color: #FFB500;
    font-size: 35px;
  }
}











.myButton_head_Desktop_fof {
	background-color:#ffb500;
	border-radius:9px;
	border:2px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#000000;
	font-family:Verdana;
	font-size:14px;
  font-weight: 600;
	padding:10px 20px;
	text-decoration:none;
}
.myButton_head_Desktop_fof:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Desktop_fof:active {
	position:relative;
	top:1px;
}

.myButton_head_Desktop_Join_Mobile{
	background-color:#FFB5003B;
	border-radius:9px;
	border:1px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
	font-family:Verdana;
	font-size:12px;
  font-weight: 600;
	padding:13px 45px;
	text-decoration:none;
}
.myButton_head_Desktop_Join_Mobile:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Desktop_Join_Mobile:active {
	position:relative;
	top:1px;
}






















































































/* Not important. General styling
------------------------------------------ */


/**************************************************
** CARDS STYLING 
***************************************************/
/* Card outside box. Here is where the 
** dimensions, shadows and borders are set 
------------------------------------------ */
.cardz{
  position: relative;
  overflow: hidden;
  display: flex;
   text-align:  center;
   
  width: 300px; /* Box dimensions */
  height: 400px; 
  
  border-radius: 4px; /* Styling */
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);  
  transition: box-shadow 0.56s ease-in-out; /* Animation */
  /* background-color: rgba(0,0,0,.2); /* for debugging */
}
/* Shows an outer shadow */
.cardz:hover{ /* Just for styling */
  cursor: pointer;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

/* Cards titles styling
------------------------------------------ */
.cardz__title{ /* Just for styling */
    align-self: flex-end; padding: 0.5rem;
    color: rgba(255,255,255,.90); font-size: 2rem;
    line-height: 1;font-weight: 600; }  

/* Styles for:
** - Using IMG tag inside a container
------------------------------------------ */
/* The image container */
.cardz__thumbnail{
  position: relative;
  overflow: hidden;  
  display: flex;
  justify-content: center; /* horizontal center */
  align-items: center; /* vertical center */
  
  width: 100%; /* Thumbnail dimensions. */
  height: 100%; /*** Change the height to make the image smaller ***/
  /* background-color: rgba(0,0,0,.2);  /* for debugging */
  
}
/* Sets the image dimensions */
.cardz__thumbnail > img{ /* Tip: use 1:1 ratio images */
  height: 100%; /* or width when img.width > img.height */ 
}  
/* Styles the title inside the img container */
.cardz__thumbnail > .cardz__title{ /* Just for styling */
  position: absolute; left: 0; bottom: 0; }


/* Styles for:
** - Using background-size on CSS
** - Using background-size on HTML
** - Using background-size on JS
------------------------------------------ */
/* Prepares a container to have cover background */
.has-bg-img{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
/* Loads a specific image to a container */
.bg-img-nature{
  background-image: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/1ffb9895-a6b3-40c5-6903-a679a5da8400/w=500")!important;
  background-color: #661c1c23 !important;
  text-align: center !important;
    justify-content: center !important;
}






.aloo{
  text-align: center;
  justify-content: center;
  align-items: center;
  display: inline-grid;
}




.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background:#fff !important;
position: absolute !important;
  left: 0;
  top: 45px !important;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
  z-index: -10 !important;
}

.progressbar-container {
  position: relative;
}

.progressbar {
  position: relative !important;
  top: 45px !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgb(252, 214, 0) !important; /* Adjust the color as needed */
}


.swiper-pagination-progressbar {

  z-index: -1 !important;
}


.backofslideone{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/f9579a01-72ed-4442-7856-76b091989000/w=2000");
  height: 100vh;
   width: 100%;
   position: relative;
   top: -24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
  padding-bottom: 50px;

}


.backofslideonee{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/f9579a01-72ed-4442-7856-76b091989000/w=2000");
  height: 100vh;
   width: 100%;
   position: relative;
   top: -24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
  padding-bottom: 50px;
}

.swiper-slide {
  transition: transform .1s ease !important;
}

.backofslidetwo{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/5ea771e0-38b6-4ca6-ac91-954acd03af00/w=2000");
  height: 100vh;
   width: 100%;
   position: relative;
   top: -24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
  padding-bottom: 50px;
}

.backofslidethree{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/4be26da9-9ad0-422f-2782-90db4474d500/w=2000");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}




.backofslidefour{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/63c0c1ad-9331-42b4-85dd-6e526bf66400/w=2000");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}


.backofslidefive{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/19d72a20-3ff4-4b80-a1db-31b191754500/w=2000");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}







/* customSwiper.css */

.backofslidesix{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/0212d5e9-cc57-46ae-850e-162f16d0d700/w=2000");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}

.backofslideseven{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/7bf16a6f-3cee-4fc8-9208-840ae3300d00/w=2000");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}









.mobbackofslideone{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/8878d62d-cae6-4418-215b-e661dec22700/w=1200");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;

}


.mobbackofslidetwo{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/1e7f5f7f-3eba-48e3-6579-d01508644900/w=1200");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;

}



.mobbackofslidethree{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/e54e61f2-367c-4302-7c5b-59704d341400/w=1200");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;

}

.mobbackofslidefour{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/3c430dae-f8ef-44b6-26ec-26a26b0fd500/w=1200");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}




.mobbackofslidefive{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/0d9f4696-aeb6-49f8-b20b-b66419fc3d00/w=1200");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}

.mobbackofslidesix{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/335bde1b-9318-4dc4-217b-68b7ae588400/w=1200");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}

.mobbackofslideseven{
  background: url("https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/b70e3c36-923f-44ee-2b81-4b46e812c800/w=1200");
  height: 100vh;
  width: 100%;
  position: relative;
  top: -24px;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 overflow-y: auto;
  padding-bottom: 50px;
}

.modal_bg{
  background-color: #1a1a1d17 !important;
}

.modals_bg{
  background-color: #1A1A1D !important;
  border-radius: 20px !important;
}
























.myButton_head_Desktop_Join_Notify {
	background-color: #d000c6;
	border-radius:9px;
	border:2px solid #D000C5;
	display:inline-block;
	cursor:pointer;
	color:#000000;
	font-family:Verdana;
	font-size:18px;
  font-weight: 600;
	padding:10px 30px;
	text-decoration:none;
}
.myButton_head_Desktop_Join_Notify:hover {
	background-color: #d000c62d;
  color: #D000C5;
}
.myButton_head_Desktop_Join_Notify:active {
	position:relative;
	top:1px;
}
















.myButton_head_Desktop_Join_Notifym {
	background-color: #d000c6;
	border-radius:9px;
	border:2px solid #D000C5;
	display:inline-block;
	cursor:pointer;
	color:#000000;
	font-family:Verdana;
	font-size:14px;
  font-weight: 600;
	padding:10px 30px;
	text-decoration:none;
}
.myButton_head_Desktop_Join_Notifym:hover {
	background-color: #d000c62d;
  color: #D000C5;
}
.myButton_head_Desktop_Join_Notifym:active {
	position:relative;
	top:1px;
}







.css-input-mobs {
  border-color: #cccccc;
  border-style: solid;
  width: 30%;
  padding: 11px;
  color: #9b9daf;
  font-size: 19px;
  border-width: 0px;
  border-radius: 9px;
  box-shadow: 0px 0px 0px 0px rgba(42, 42, 42, 0.55);
  text-shadow: 0px 0px 0px rgba(42, 42, 42, 0.62);
  background-color: #7c017091;
}
.css-input-mobs:focus {
  outline: none;
}




.footer_last{
  background-color: #000000;
}

.last_footer_left_text{
padding: 20px;
color: #fff;
text-decoration: none;
}

.last_footer_left_text :hover{
  padding: 20px;
  color: #dd00d2 !important;
  text-decoration: none;
  }










  .myButton_head_Desktop_Joint {
    background-color:#FFB5003B;
    border-radius:4px;
    border:2px solid #ffb500;
    display:inline-block;
    cursor:pointer;
    color:#ffb500;
    font-family:Verdana;
    font-size:14px;
    font-weight: 600;
    padding:5px 22px;
    text-decoration:none;
    margin-bottom: 12px;
    display: block;
    width: fit-content;
    margin-left: auto;
  }

  .myButton_head_Desktop_Joint:hover {
    background-color:#ffab23;
    color: #000000;
  }
  .myButton_head_Desktop_Joint:active {
    position:relative;
    top:1px;
  }

  .bgworld{
    background: url('https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/602fa850-a481-4f30-300e-bed99b0a0400/w=2200');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    backface-visibility: visible;
    background-blend-mode: multiply; /* blend mode to multiply the image and color */
    background-repeat: no-repeat;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: center center;
  }


  .image_world_align{	
    position: relative;	
    right: 80px;	
    border-radius: 10px;	
  }	
  	
  .image_world_align_under{	
    position: relative;	
    bottom: 30px;	
z-index: -1;	
  }	
  .mobile_top_world{	
    position: relative;	
    top: 90px;	
    margin-bottom: -80px;
  }