

.swipers {
 width: 100%;
  height: 67%;
  padding: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (min-width: 1300px) {
  .swipers {
    width: 50%;
     height: 70%;
     padding: 70px;
     padding-top: 10px;
   }
}
.swiper-slides {
  background-position: center;
  background-size: cover;
  width: 50%;
  height: 100%;
}


@media screen and (min-width: 1300px) {
  .swiper-slides {
    background-position: center;
    background-size: cover;
    width: 50%;
    height: 500px;
  }
}


.swiper-slides img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: black;
}


@media screen and (min-width: 1300px) {
  
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
}