.view-in-room {
    max-width: 800px;
}

.view-in-room__container {
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #242424;
    border-radius: 5px;
    height: 500px;
    padding: 15px;
}

.view-in-room__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 15px;
}

.view-in-room__header-text {
    font-weight: 500;
    color: white;
    font-size: 15px;
}

.view-in-room__close {
    width: 15px;
    stroke: white;
    cursor: pointer;
    position: absolute;
    right: 0;
}

@media (max-width: 800px) {
    .view-in-room__container {
        height: 80vh;
    }
}