body {
  min-height: 100vh;
  background-color: black;
}

.head_of_slideone{
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}

@media screen and (min-width: 1000px) {
    .head_of_slideone{
        color: #fff;
        font-size: 50px;
        font-weight: 400;
    }
    
  }

  .para_of_slideone{
    color: #fff;
    font-weight: 400;
    font-size: 14px;
  }

  @media screen and (min-width: 1000px) {
    .para_of_slideone{
      color: #fff;
      font-weight: 500;
      font-size: 15px;
    }
    
  }


  .css-input {
    padding: 6px;
    font-size: 13px;
    border-width: 3px;
    border-color: #101013;
    background-color: #101013;
    color: #dcdcdc;
    border-style: solid;
    border-radius: 9px;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(66,66,66,.75);
    text-shadow: 0px 0px 5px rgba(66,66,66,.75);
}
.css-input:focus {
    outline:none;
}


@media screen and (min-width: 1000px) {
    .css-input {
        padding: 9px;
        font-size: 18px;
        border-width: 3px;
        border-color: #101013;
        background-color: #101013;
        color: #dcdcdc;
        width: 90%;
        border-style: solid;
        border-radius: 9px;
        box-shadow: 0px 0px 5px rgba(66,66,66,.75);
        text-shadow: 0px 0px 5px rgba(66,66,66,.75);
    }
    .css-input:focus {
        outline:none;
    }
  }
















  .b-contain *,
  .b-contain *::before,
  .b-contain *::after {
    box-sizing: content-box !important;
  }
  
  .b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  
  .b-contain span {
    line-height: 0.81;
    font-size: 0.59rem;
    font-family: inherit;
  }
  @media screen and (min-width: 1000px) {
    .b-contain span {
      line-height: 0.81;
      font-size: 0.99rem;
      font-family: inherit;
    }
  }

  .b-contain {
    display: table;
    position: relative;
    padding-left: 2.9rem;
    cursor: pointer;
    margin-bottom: 2rem;
  }
  
  .b-contain input[type="checkbox"] ~ .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.29rem;
    width: 1.4rem;
    background: rgb(33, 33, 33);
    transition: background 250ms;
    border: 2px solid rgb(33, 33, 33);
    border-radius: 0.2rem;
  }
  
  
  .b-contain input[type="radio"] ~ .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.29rem;
    width: 1.4rem;
    background: rgb(33, 33, 33);
    transition: background 250ms;
    border: 2px solid rgb(33, 33, 33);
    border-radius: 3.96rem;
  }
  
  .b-contain input[type="checkbox"] ~ .b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 0.34rem;
    height: 0.71rem;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
  }
  
  .b-contain input[type="radio"] ~ .b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 4px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 3rem;
    background: #ffffff;
    transition: background 250ms;
  }
  
  .b-contain input[type="checkbox"]:disabled ~ .b-input::after {
    border-color: #ffffff;
  }
  
  .b-contain input:checked ~ .b-input::after {
    display: block;
  }
  
  .b-contain:hover input[type="checkbox"]:not([disabled]) ~ .b-input,
  .b-contain input[type="checkbox"]:focus ~ .b-input {
    background: rgb(53, 53, 53);
    border-color: #64748b;
  }
  
  .b-contain:hover input[type="radio"]:not([disabled]) ~ .b-input,
  .b-contain input[type="radio"]:focus ~ .b-input {
    background: #e2e8f0;
    border-color: #64748b;
  }
  
  .b-contain input:focus ~ .b-input {
    box-shadow: 0 0 0 2px rgb(33, 33, 33);
  }
  
  .b-contain input[type="checkbox"]:checked ~ .b-input {
    background: rgb(75, 75, 75);
    border-color: #1d4ed8;
  }
  
  .b-contain input[type="radio"]:checked ~ .b-input {
    background: #3b82f6;
    border-color: #1d4ed8;
  }
  
  .b-contain input[type="checkbox"]:disabled ~ .b-input,
  .b-contain input[type="radio"]:disabled ~ .b-input {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .b-contain input[type="radio"]:disabled ~ .b-input::after {
    background: #ffffff;
  }
  
  .b-contain:hover input[type="checkbox"]:not([disabled]):checked ~ .b-input,
  .b-contain input[type="checkbox"]:checked:focus ~ .b-input {
    background: rgb(169, 169, 169);
    border-color: rgb(75, 75, 75);
  }
  
  .b-contain:hover input[type="radio"]:not([disabled]):checked ~ .b-input,
  .b-contain input[type="radio"]:checked:focus ~ .b-input {
    background: rgb(53, 53, 53);
    border-color: rgb(53, 53, 53);
  }


  .move_check_field{
    position: relative;
    left: 50px;
  }






  .head_of_slidetwo{
    color: #fff;
    font-size: 17px;
    font-weight: 300;
}

@media screen and (min-width: 1000px) {
    .head_of_slidetwo{
        color: #fff;
        font-size: 35px;
        font-weight: 300;
    }
    
  }












  
.myButton_head_Desktop_yes {
	background-color:#FFB5003B;
  border-radius:6px;
	border:2px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
	font-family:Verdana;
	font-size:13px;
  font-weight: 600;
	padding:16px 50px;
  width: 200px;
	text-decoration:none;
}
.myButton_head_Desktop_yes:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Desktop_yes:active {
	position:relative;
	top:1px;
}



.myButton_head_Desktop_no {
	background-color:#9b9daf3b
  ;
  border-radius:6px;
	border:2px solid #9B9DAF
  ;
	display:inline-block;
	cursor:pointer;
	color:#9B9DAF
  ;
	font-family:Verdana;
	font-size:13px;
  font-weight: 600;
	padding:16px 40px;
  width: 200px;
	text-decoration:none;
}
.myButton_head_Desktop_no:hover {
	background-color:  #9B9DAF;
  color: #000000;
}
.myButton_head_Desktop_no:active {
	position:relative;
	top:1px;
}



.myButton_head_Desktop_noo {
	background-color:#9b9daf3b
  ;
  border-radius:6px;
	border:2px solid #9B9DAF
  ;
	display:inline-block;
	cursor:pointer;
	color:#9B9DAF
  ;
	font-family:Verdana;
	font-size:10px;
  font-weight: 600;
	padding:12px 30px;
  width: 150px;
	text-decoration:none;
}
.myButton_head_Desktop_noo:hover {
	background-color:  #9B9DAF;
  color: #000000;
}
.myButton_head_Desktop_noo:active {
	position:relative;
	top:1px;
}



.myButton_head_Desktop_yess {
	background-color:#FFB5003B;
  border-radius:6px;
	border:2px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
  font-family:Verdana;
	font-size:10px;
  font-weight: 600;
	padding:12px 30px;
  width: 150px;
	text-decoration:none;
}
.myButton_head_Desktop_yess:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Desktop_yess:active {
	position:relative;
	top:1px;
}




.css-inputs {
  padding: 9px;
  font-size: 13px;
  border-width: 3px;
  border-color: #101013;
  background-color: #101013;
  color: #dcdcdc;
  border-style: solid;
  border-radius: 9px;
  width: 90%;
  box-shadow: 0px 0px 5px rgba(66,66,66,.75);
  text-shadow: 0px 0px 5px rgba(66,66,66,.75);
}
.css-inputs.head_of_slidetwo {
  outline:none;
}


@media screen and (min-width: 1000px) {
  .css-inputs {
      padding: 9px;
      font-size: 18px;
      border-width: 3px;
      border-color: #101013;
      background-color: #101013;
      color: #dcdcdc;
      width: 40%;
      border-style: solid;
      border-radius: 9px;
      box-shadow: 0px 0px 5px rgba(66,66,66,.75);
      text-shadow: 0px 0px 5px rgba(66,66,66,.75);
  }
  .css-inputs:focus {
      outline:none;
  }
}




.css-inputs::placeholder {
  color:#FF7900; /* Choose your desired color */
  font-weight: 800;
  font-size: 25px;
}




.myButton_head_Desktop_Choose {
	background-color:
  #ff770069;
  border-radius:6px;
	border:2px solid 
  #FF7900;
	display:inline-block;
	cursor:pointer;
	color:
  #FF7900;
	font-family:Verdana;
	font-size:15px;
  font-weight: 600;
	padding:16px 50px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose:hover {
	background-color:
  #FF7900;
  color: #000000;
}
.myButton_head_Desktop_Choose:active {
	position:relative;
	top:1px;
}



.myButton_head_Desktop_Choosee {
	background-color:
  #ff770069;
  border-radius:6px;
	border:2px solid 
  #FF7900;
	display:inline-block;
	cursor:pointer;
	color:
  #FF7900;
	font-family:Verdana;
	font-size:12px;
  font-weight: 600;
	padding:12px 30px;
	text-decoration:none;
}
.myButton_head_Desktop_Choosee:hover {
	background-color:
  #FF7900;
  color: #000000;
}
.myButton_head_Desktop_Choosee:active {
	position:relative;
	top:1px;
}



.css-input_bussines {
  padding: 9px;
  font-size: 18px;
  border-width: 3px;
  border-color: #101013;
  background-color: #101013;
  color: #dcdcdc;
  border-style: solid;
  border-radius: 3px;
  width: 80%;
  box-shadow: 0px 0px 5px rgba(66,66,66,.75);
  text-shadow: 0px 0px 5px rgba(66,66,66,.75);
}
.css-input_bussines:focus {
  outline:none;
}


@media screen and (min-width: 1000px) {
  .css-input_bussines {
      padding: 9px;
      font-size: 18px;
      border-width: 3px;
      border-color: #101013;
      background-color: #101013;
      color: #dcdcdc;
      width: 100%;
      border-style: solid;
      border-radius: 3px;
      box-shadow: 0px 0px 5px rgba(66,66,66,.75);
      text-shadow: 0px 0px 5px rgba(66,66,66,.75);
  }
  .css-input_bussines:focus {
      outline:none;
  }

  .css-input_bussines::placeholder {
    font-size: 12px;
    top: -10px;
    position: relative;
  }
}
















.css-input_Phone_number {
  padding: 9px;
  font-size: 13px;
  border-width: 3px;
  border-color: #101013;
  background-color: #101013;
  color: #dcdcdc;
  border-style: solid;
  border-radius: 3px;
  width: 80%;
  height: 10%;
  box-shadow: 0px 0px 5px rgba(66,66,66,.75);
  text-shadow: 0px 0px 5px rgba(66,66,66,.75);
}
.css-input_Phone_number:focus {
  outline:none;
}


@media screen and (min-width: 1000px) {
  .css-input_Phone_number {
      padding: 9px;
      font-size: 18px;
      border-width: 3px;
      border-color: #101013;
      background-color: #101013;
      color: #dcdcdc;
      width: 100%;
      border-style: solid;
      border-radius: 3px;
      margin-bottom: 15px;
      border-radius: 3px;
      box-shadow: 0px 0px 5px rgba(66,66,66,.75);
      text-shadow: 0px 0px 5px rgba(66,66,66,.75);
  }
  .css-input_Phone_number:focus {
      outline:none;
  }

  .css-input_Phone_number::placeholder {
    font-size: 12px;
    top: -10px;
    position: relative;
  }
}


.css-input_Phone_numbers {
  padding: 9px;
  font-size: 18px;
  border-width: 3px;
  border-color: #101013;
  background-color: #101013;
  color: #dcdcdc;
  border-style: solid;
  border-radius: 3px;
  width: 80%;
  box-shadow: 0px 0px 5px rgba(66,66,66,.75);
  text-shadow: 0px 0px 5px rgba(66,66,66,.75);
}
.css-input_Phone_numbers:focus {
  outline:none;
}


@media screen and (min-width: 1000px) {
  .css-input_Phone_numbers {
      padding: 9px;
      font-size: 18px;
      border-width: 3px;
      border-color: #101013;
      background-color: #101013;
      color: #dcdcdc;
      width: 55%;
      border-style: solid;
      border-radius: 3px;
      margin-bottom: 15px;
      border-radius: 3px;
      box-shadow: 0px 0px 5px rgba(66,66,66,.75);
      text-shadow: 0px 0px 5px rgba(66,66,66,.75);
  }
  .css-input_Phone_numbers:focus {
      outline:none;
  }

  .css-input_Phone_numbers::placeholder {
    font-size: 12px;
    top: -10px;
    position: relative;
  }
}

































.select {
  position: relative;
  display: inline-block;
  font-size: 15px;
  margin-bottom: 15px;
  border-radius: 3px;
  width:48%;
}    .select select {
      font-family: 'Arial';
      display: inline-block;
      width: 100%;
      cursor: pointer;    
      padding: 12px 12px;
      outline: 0;
      border: 0px solid #101013;
      border-radius: 3px;
      background: #101013;
      color: 
      
      #9B9DAF;
      box-shadow: 0px 0px 5px rgba(66,66,66,.75);
      text-shadow: 0px 0px 5px rgba(66,66,66,.75);
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
  }
      .select select::-ms-expand {
          display: none;
      }
      .select select:hover,
      .select select:focus {
          color: #ffffff;
          background: #101013;
      }
      .select select:disabled {
          opacity: 0.5;
          pointer-events: none;
      }
.select_arrow {
  position: absolute;
  top: 14px;
  right: 13px;
  width: 10px;
  height: 10px;
  border: solid #FF3B58;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
  border-color: #FF3B58;
}
.select select:disabled ~ .select_arrow {
  border-top-color: #FF3B58;
}



@media screen and (min-width: 1000px) {
  .select {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    border-radius: 3px;
    width:48%;
  }    .select select {
        font-family: 'Arial';
        display: inline-block;
        width: 100%;
        cursor: pointer;    
        padding: 13px 13px;
        outline: 0;
        border: 0px solid #101013;
        border-radius: 3px;
        background: #101013;
        color: 
        
        #9B9DAF;
        box-shadow: 0px 0px 5px rgba(66,66,66,.75);
        text-shadow: 0px 0px 5px rgba(66,66,66,.75);
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
        .select select::-ms-expand {
            display: none;
        }
        .select select:hover,
        .select select:focus {
            color: #ffffff;
            background: #101013;
        }
        .select select:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
  .select_arrow {
    position: absolute;
    top: 13px;
    right: 18px;
    width: 14px;
    height: 14px;
    border: solid #FF3B58;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .select select:hover ~ .select_arrow,
  .select select:focus ~ .select_arrow {
    border-color: #FF3B58;
  }
  .select select:disabled ~ .select_arrow {
    border-top-color: #FF3B58;
  }
  
}


















.gender{  font-size: 12px !important;
  top: -10px !important;
  position: relative !important;}

.gender_drop{
  display: flex;
}







.myButton_head_Desktop_Choose_pink {
	background-color:
  #ff3b584f;
  border-radius:6px;
	border:2px solid 
  #FF3B58;
	display:inline-block;
	cursor:pointer;
	color:
  #FF3B58;
	font-family:Verdana;
	font-size:12px;
  font-weight: 600;
	padding:12px 40px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink:hover {
	background-color:
  #FF3B58;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink:active {
	position:relative;
	top:1px;
}

@media screen and (min-width: 1000px) {
  .myButton_head_Desktop_Choose_pink {
    background-color:
    #ff3b584f;
    border-radius:6px;
    border:2px solid 
    #FF3B58;
    display:inline-block;
    cursor:pointer;
    color:
    #FF3B58;
    font-family:Verdana;
    font-size:15px;
    font-weight: 600;
    padding:16px 50px;
    text-decoration:none;
  }
  .myButton_head_Desktop_Choose_pink:hover {
    background-color:
    #FF3B58;
    color: #000000;
  }
  .myButton_head_Desktop_Choose_pink:active {
    position:relative;
    top:1px;
  }
}


.myButton_head_Desktop_Choose_pink_zan {
	background-color:
  #ff00994b;
  border-radius:6px;
	border:2px solid 
  #FF0097;
	display:inline-block;
	cursor:pointer;
	color:
  #FF0097;
	font-family:Verdana;
	font-size:15px;
  font-weight: 600;
	padding:16px 50px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink_zan:hover {
	background-color:
  #FF0097;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink_zan:active {
	position:relative;
	top:1px;
}













.myButton_head_Desktop_Choose_pink_zanc {
	background-color:
  #d000c654;

	border:2px solid 
  #D000C5;
	display:inline-block;
	cursor:pointer;
	color:
  #D000C5;
	font-family:Verdana;
	font-size:15px;
	border-radius:6px;
  font-weight: 800;
	padding:16px 50px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink_zanc:hover {
	background-color:
  #D000C5;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink_zanc:active {
	position:relative;
	top:1px;
}







.myButton_head_Desktop_Choose_pink_zancg {
	background-color:
  #9b9daf4f;
	border-radius:6px;
  font-weight: 800;
	border:2px solid 
  #9B9DAF;
	display:inline-block;
	cursor:pointer;
	color:
  #9B9DAF;
	font-family:Verdana;
	font-size:15px;
  
	padding:16px 50px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink_zancg:hover {
	background-color:
  #9B9DAF;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink_zancg:active {
	position:relative;
	top:1px;
}






.myButton_head_Desktop_Choose_pink_zancgg {
	background-color:
  #9b9daf4f;
	border-radius:6px;
  font-weight: 800;
	border:2px solid 
  #9B9DAF;
	display:inline-block;
	cursor:pointer;
	color:
  #9B9DAF;
	font-family:Verdana;
	font-size:13px;
  font-weight: 600;
	padding:12px 30px;
  width: 120px;
	text-decoration:none;
}

.myButton_head_Desktop_Choose_pink_zancgg:hover {
	background-color:
  #9B9DAF;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink_zancgg:active {
	position:relative;
	top:1px;
}









.myButton_head_Desktop_Choose_pink_zanck {
	background-color:
  #d000c654;

	border:2px solid 
  #D000C5;
	display:inline-block;
	cursor:pointer;
	color:
  #D000C5;
	font-family:Verdana;

	border-radius:6px;
	font-family:Verdana;
	font-size:10px;
  font-weight: 600;
	padding:12px 30px;
  width: 120px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink_zanck:hover {
	background-color:
  #D000C5;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink_zanck:active {
	position:relative;
	top:1px;
}















.myButton_head_Desktop_Choose_pink_zancc {
	

	
	display:inline-block;
	cursor:pointer;
	color:
  #D000C5;
	font-family:Verdana;
	font-size:15px;
	border-radius:6px;
  font-weight: 800;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink_zancc:hover {


}
.myButton_head_Desktop_Choose_pink_zancc:active {
	position:relative;
	top:1px;
}


.myButton_head_Mobilee{
	background-color:#FFB5003B;
	border-radius:9px;
	border:1px solid #ffb500;
	display:inline-block;
	cursor:pointer;
	color:#ffb500;
	font-family:Verdana;
	font-size:10px;
  font-weight: 600;
	padding:10px 35px;
	text-decoration:none;
}
.myButton_head_Mobilee:hover {
	background-color:#ffab23;
  color: #000000;
}
.myButton_head_Mobilee:active {
	position:relative;
	top:1px;
}














.myButton_head_Desktop_Choose_pink_zann {
	background-color:
  #ff00994b;
  border-radius:6px;
	border:2px solid 
  #FF0097;
	display:inline-block;
	cursor:pointer;
	color:
  #FF0097;
	font-family:Verdana;
	font-size:12px;
  font-weight: 600;
	padding:12px 40px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink_zann:hover {
	background-color:
  #FF0097;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink_zann:active {
	position:relative;
	top:1px;
}






.head_of_slidetwom{
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .head_of_slidetwom{
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      text-align: center;
  }
  
}




.para_of_slideonm{
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .para_of_slideonem{
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
  
}





























.myButton_head_Desktop_Choose_pink_zanckm1 {
	background-color:
  #ffb70044;

	border:2px solid 
  #FFB500;
	display:inline-block;
	cursor:pointer;
	color:
  #FFB500;
	font-family:Verdana;

	border-radius:6px;
	font-family:Verdana;
	font-size:14px;
  font-weight: 600;
	padding:12px 30px;
  width: 120px;
	text-decoration:none;
}
.myButton_head_Desktop_Choose_pink_zanckm1:hover {
	background-color:
  #FFB500;
  color: #000000;
}
.myButton_head_Desktop_Choose_pink_zanck:active {
	position:relative;
	top:1px;
}



























.css-input_bussinescc {
  padding: 9px;
  font-size: 15px;
  border-width: 3px;
  border-color: #101013;
  background-color: #0c0c0e;
  color: #dcdcdc;
  border-style: solid;
  border-radius: 7px;
  width: 80%;
  box-shadow: 0px 0px 5px rgba(66,66,66,.75);
  text-shadow: 0px 0px 5px rgba(66,66,66,.75);
}
.css-input_bussinescc:focus {
  outline:none;
}


@media screen and (min-width: 1000px) {
  .css-input_bussinescc {
      padding: 9px;
      font-size: 18px;
      border-width: 3px;
      border-color: #101013;
      background-color: #0d0d0e;
      color: #dcdcdc;
      width: 100%;
      border-style: solid;
      border-radius: 7px;
      box-shadow: 0px 0px 5px rgba(66,66,66,.75);
      text-shadow: 0px 0px 5px rgba(66,66,66,.75);
  }
  .css-input_bussinescc:focus {
      outline:none;
  }

  .css-input_bussinescc::placeholder {
    font-size: 12px;
    top: -10px;
    position: relative;
  }
}